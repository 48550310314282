<template>
  <div class="app-wrap">
    <h3>银行卡管理</h3>
    <div class="content">
      <div class="content_left">
        <div>
          <span>{{ bankList.length }}</span>
          <span>张</span>
        </div>
        <span>已绑定银行卡</span>
      </div>
      <div class="content_right">
        <div
          v-for="item in bankList"
          :key="item.acctId"
          class="card-item"
        >
          <div class="bank-card">
            <div class="card-top">
              <div class="img_left">
                <img src="@/assets/images/myFavors/bc1.png">
                <img src="@/assets/images/myFavors/bc2.png">
              </div>
              <img :src="item.logoUrl">
            </div>
            <div class="card-center">
              <span>{{ item.bankAccount | formatBankNum }}</span>
            </div>
            <div
              class="card-bottom"
              :style="{
                'justify-content': item.unbindFlag == 1 ? 'space-between' : 'flex-end'
              }"
            >
              <span v-if="item.unbindFlag == 1" @click="handleUntie(item)">解绑</span>
              <img src="@/assets/images/myFavors/bc4.png">
            </div>
          </div>
          <div class="bank-info">
            <div>
              <span>{{ item.bankName.replace('中国', '') }}</span>
            </div>
            <div />
            <div>
              <span>单笔限额:{{ item.singelLimit }}</span>
              <span>单日限额:{{ item.singeldayLimit }}</span>
            </div>
          </div>
        </div>
        <div class="card-item item-add">
          <div class="bank-card">
            <div class="card-top">
              <div class="img_left">
                <img src="@/assets/images/myFavors/bc1.png">
                <img src="@/assets/images/myFavors/bc6.png">
              </div>
              <img src="@/assets/images/myFavors/bc5.png">
            </div>
            <div class="card-info">
              <span>新增银行卡</span>
              <span>请选择本人名下的Ⅰ类银行储蓄卡</span>
            </div>
          </div>
          <div
            class="bank-add"
            @click="handleGoAddBank"
          >
            <span>新增银行卡</span>
          </div>
        </div>
      </div>
    </div>


    <!-- <Models
      :btn-text="btnText"
      :showmodel="showmodel"
      @cancels="modelClose('0')"
      @confirms="modelClose('1')"
      @showmodels="showmodel=false"
    >
      <div class="modal-box">
        <div class="title">
          解绑提示
        </div>
        <div class="tips">
          确认解绑当前银行卡吗？
        </div>
      </div>
    </Models> -->


    <Models
      :showmodel="showmodel"
      @cancels="modelClose('0')"
      @confirms="modelClose('1')"
      @showmodels="showmodel=false"
    >
      <div class="cont_models">
        <div class="titles_ab">
          确定解绑该银行卡吗？
        </div>
        <div class="inputs__">
          请输入交易密码
        </div>
        <PasswordInput v-model="tradPW" />
      </div>
    </Models>
  </div>
</template>

<script>
import {getBankList,untieBankAcco} from "@/api/bankmanagement";
import Models from "@/components/Models";
import PasswordInput from "@/components/PasswordInput";
export default {
  name: "BankCardManagement",
  components: {
    Models,
    PasswordInput
  },
  data() {
    return {
      showmodel: false,
      btnText: {
          cancel: "取消",
          comfirm: "确认",
      },
      tradPW: '',
      bankList: [],
      itemData: {}
    }
  },
  mounted() {
    this.getBankList();
  },
  methods: {
    modelClose(val) {
      
      if (val === '1') {
        if (this.tradPW === '') {
          this.$message({
            message: '请输入交易密码！',
            type: 'warning'
          });
        }
        untieBankAcco({
          "bankAccount": this.itemData.bankAccount,
          "tradePassword": this.tradPW
        }).then(() => {
          this.showmodel = false
        })
      } else {
        this.showmodel = false
      }
    },
    handleGoAddBank() {
      this.$router.push({
        name: 'BankCardAdd'
      })
    },
    getBankList() {
      getBankList().then(({data}) => {
        this.bankList = data || []
      })
    },
    handleUntie(item) {
      this.itemData = item
      this.showmodel = true
    }
  }
}
</script>

<style scoped lang="less">
@import url('@/assets/css/zc-common.less');
.card {
  width: 100%;
  height: 172.2px;
  background-color: #fff;
  border-radius: 14.1147px;
  padding-top: 23.58px;
  padding-left: 21.9px;
  padding-right: 16.24px;
  .border-box();
  .card-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img_left {
      display: flex;
      align-items: center;
      img {
        &:first-child {
          width: 28.78px;
          height: 21.79px;
        }
        &:last-child {
          width: 13.41px;
          height: 15.64px;
          margin-left: 11px;
        }
      }
    }
    & > img {
      width: 26px;
      height: 26px;
    }
  }
  .card-center {
    width: 100%;
    padding-top: 25.32px;
    font-size: 18px;
    color: #25293D;
    letter-spacing: 3px;
  }
  .card-bottom {
    width: 100%;
    padding-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>span {
      display: inline-flex;
      width: 80px;
      height: 20px;
      border: 0.6px solid #CE9B63;
      border-radius: 2px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #B88141;
      cursor: pointer;
    }
    &>img {
      width: 49.03px;
      height: 30.65px;
    }
  }
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
  div {
    &:nth-child(1) {
      font-size: 20px;
      color: #1F1F1F;
    }
    &:nth-child(2) {
      width: 1.41px;
      height: 62.1px;
      background: #A5A5A5;
      border-radius: 1.41147px;
    }
    &:nth-child(3) {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #1F1F1F;
      padding-right: 18px;
      padding-top: 4px;
      span{
        &:last-child {
          margin-top: 12px;
        }
      }
    }
  }
}
.add {
  .card-top {
    height: 82px;
    .img_left {
      display: flex;
      align-items: center;
      margin-top: 24px;
      img {
        &:first-child {
          width: 28.78px;
          height: 23.99px;
        }
        &:last-child {
          width: 13.41px;
          height: 17.22px;
          margin-left: 11px;
        }
      }
    }
    & > img {
      width: 94px;
      height: 82px;
    }
  }
  .card-info {
    width: 100%;
    margin-top: 13.86px;
    display: flex;
    flex-direction: column;
    span {
      &:first-child {
        font-size: 14px;
        color: #000000;
      }
      &:last-child {
        font-size: 12px;
        color: #B88141;
        margin-top: 5px;
      }
    }
  }
}
.app-wrap > h3 {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #1F1F1F;
}
.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  .content_left {
    width: 100px;
    overflow: hidden;
    &>div {
      display: flex;
      align-items: flex-end;
      overflow: hidden;
      margin-bottom: 20px;
      span{
        &:first-child {
          font-weight: bold;
          font-size: 70.4px;
          color: #CE9B63;
        }
        &:last-child {
          margin-left: 10px;
          font-size: 14px;
          color: #A5A5A5;
          margin-bottom: 12px;
        }
      }
    }
    &>span {
      font-size: 14px;
      color: #1F1F1F;
    }
  }
  .content_right {
    width: 608px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    .card-item {
      width: 295px;
      height: 295px;
      background: #F6F5F3;
      border-radius: 14.1147px;
      margin-top: 18px;
      padding: 16px 13px;
      box-sizing: border-box;
      &:nth-child(1), &:nth-child(2) {
        margin-top: 0;
      }
      .bank-card {
        .card()
      }
      .bank-info {
        .info()
      }
      &.item-add {
        .bank-card {
          .add()
        }
        .bank-add {
          width: 100%;
          height: 62px;
          margin-top: 26px;
          background: linear-gradient(109.88deg, #F7D9B7 -3.02%, #EABF96 98.27%);
          border-radius: 12.8707px;
          font-size: 16px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    
  }
}
.modal-box {
  padding-bottom: 40px;
  .title {
    font-weight: bold;
    font-size: 19px;
    color: #000000;
  }
  .tips {
    margin-top: 24px;
    font-size: 16px;
    color: #000000;
  }
}
.cont_models {
  .titles_ab {
    font-size: 19px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
    font-weight: 700;
    color: #000000;
    margin-bottom: 14px;
  }
  .models_txt {
    font-size: 16px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
    font-weight: 300;
    color: #000000;
    margin-bottom: 16px;
  }
  .inputs__ {
    font-size: 14px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
    font-weight: 400;
    color: rgba(21, 25, 32, 0.5);
    margin-bottom: 16px;
  }
}
</style>